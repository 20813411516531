import React from "react";
import { Link } from "gatsby";
import { MailIcon, PhoneIcon } from '@heroicons/react/outline'
import { DarkFooter as Footer } from "@components/footers";
import Layout from "@components/layout";
import SEO from "@components/seo";
import MenuBar from "@components/menuBar/MenuBar";

const Form = () => (
  <div className="bg-white py-4 px-4 overflow-hidden sm:px-6 lg:px-8 lg:py-12 lg:pb-24">
    <div className="relative max-w-xl mx-auto">
      <svg
        className="absolute left-full transform translate-x-1/2"
        width={404}
        height={404}
        fill="none"
        viewBox="0 0 404 404"
        aria-hidden="true"
      >
        <defs>
          <pattern
            id="85737c0e-0916-41d7-917f-596dc7edfa27"
            x={0}
            y={0}
            width={20}
            height={20}
            patternUnits="userSpaceOnUse"
          >
            <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
          </pattern>
        </defs>
        <rect width={404} height={404} fill="url(#85737c0e-0916-41d7-917f-596dc7edfa27)" />
      </svg>
      <svg
        className="absolute right-full bottom-0 transform -translate-x-1/2"
        width={404}
        height={404}
        fill="none"
        viewBox="0 0 404 404"
        aria-hidden="true"
      >
        <defs>
          <pattern
            id="85737c0e-0916-41d7-917f-596dc7edfa27"
            x={0}
            y={0}
            width={20}
            height={20}
            patternUnits="userSpaceOnUse"
          >
            <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
          </pattern>
        </defs>
        <rect width={404} height={404} fill="url(#85737c0e-0916-41d7-917f-596dc7edfa27)" />
      </svg>

      <div className="">
        <h2 className="text-2xl font-extrabold text-gray-900 sm:text-4xl">Gift Card Support</h2>
        <div className="mt-3">
          <p className="text-lg text-gray-500">
            Need help with your gift card? No problem!
            Remember, you can always check your WAIVPAY gift card balance online at <Link to="/balance-check" className='text-highlight'>Balance Check</Link>.
          </p>
        </div>
        <div className="md:grid md:grid-cols-2 md:gap-8 mt-9">
          <div className="flex">
            <div className="flex-shrink-0">
              <PhoneIcon className="h-6 w-6 text-gray-400" aria-hidden="true" />
            </div>
            <div className="ml-3 text-base text-gray-500">
              <p><a href="tel:1300764721">1300 764 721</a></p>
              <p className="mt-1">Mon-Fri 8am to 6pm PST</p>
            </div>
          </div>
          <div className="mt-6 md:mt-0 flex">
            <div className="flex-shrink-0">
              <MailIcon className="h-6 w-6 text-gray-400" aria-hidden="true" />
            </div>
            <div className="ml-3 text-base text-gray-500">
              <p><a href="mailto:support@waivpay.com">support@waivpay.com</a></p>
            </div>
          </div>
        </div>
      </div>

      <hr className="my-12 sm:my-16"/>

      <div className="mt-12 sm:mt-16">
        <h2 className="text-2xl font-extrabold text-gray-900 sm:text-4xl">Contact&nbsp;Sales</h2>
        <div className="mt-3">
          <p className="text-lg text-gray-500">
            Need help with your own gift cards, a gifting platform, cashbacks or a loyalty program? We&rsquo;re here to help.
          </p>
        </div>
        <div className="md:grid md:grid-cols-2 md:gap-8 mt-9">
          <div className="flex">
            <div className="flex-shrink-0">
              <PhoneIcon className="h-6 w-6 text-gray-400" aria-hidden="true" />
            </div>
            <div className="ml-3 text-base text-gray-500">
              <p><a href="tel:+61291193807">+61 2 9119 3807</a></p>
            </div>
          </div>
          <div className="mt-6 md:mt-0 flex">
            <div className="flex-shrink-0">
              <MailIcon className="h-6 w-6 text-gray-400" aria-hidden="true" />
            </div>
            <div className="ml-3 text-base text-gray-500">
              <p><a href="mailto:sales@waivpay.com">sales@waivpay.com</a></p>
            </div>
          </div>
        </div>
      </div>

      <h2 className="mt-12 text-xl font-extrabold text-gray-900 sm:text-2xl">Phone phobia? Use this form.</h2>
      <div className="mt-8 sm:mt-12">
        <form name="sales" method="POST" className="grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8" netlify-honeypot="bot-field" data-netlify="true">
          <p className="hidden">
            <input type="hidden" name="form-name" value="sales" />
            <input name="bot-field" />
          </p>
          <div>
            <label htmlFor="first_name" className="block text-sm font-medium text-gray-700">
              First name
            </label>
            <div className="mt-1">
              <input
                type="text"
                name="firstName"
                id="firstName"
                autoComplete="given-name"
                className="py-3 px-4 block w-full shadow-sm focus:ring-primary focus:border-primary border-gray-300 rounded-md"
              />
            </div>
          </div>
          <div>
            <label htmlFor="last_name" className="block text-sm font-medium text-gray-700">
              Last name
            </label>
            <div className="mt-1">
              <input
                type="text"
                name="lastName"
                id="lastName"
                autoComplete="family-name"
                className="py-3 px-4 block w-full shadow-sm focus:ring-primary focus:border-primary border-gray-300 rounded-md"
              />
            </div>
          </div>
          <div className="sm:col-span-2">
            <label htmlFor="company" className="block text-sm font-medium text-gray-700">
              Company
            </label>
            <div className="mt-1">
              <input
                type="text"
                name="company"
                id="company"
                autoComplete="organization"
                className="py-3 px-4 block w-full shadow-sm focus:ring-primary focus:border-primary border-gray-300 rounded-md"
              />
            </div>
          </div>
          <div className="sm:col-span-2">
            <label htmlFor="email" className="block text-sm font-medium text-gray-700">
              Email
            </label>
            <div className="mt-1">
              <input
                id="email"
                name="email"
                type="email"
                autoComplete="email"
                className="py-3 px-4 block w-full shadow-sm focus:ring-primary focus:border-primary border-gray-300 rounded-md"
              />
            </div>
          </div>
          <div className="sm:col-span-2">
            <label htmlFor="phone_number" className="block text-sm font-medium text-gray-700">
              Phone Number
            </label>
            <div className="mt-1 relative rounded-md shadow-sm">
              <input
                type="text"
                name="phoneNumber"
                id="phoneNumber"
                autoComplete="tel"
                className="py-3 px-4 block w-full xpl-20 focus:ring-primary focus:border-primary border-gray-300 rounded-md"
                placeholder="landline or mobile"
              />
            </div>
          </div>
          <div className="sm:col-span-2">
            <label htmlFor="message" className="block text-sm font-medium text-gray-700">
              Message
            </label>
            <div className="mt-1">
              <textarea
                id="message"
                name="message"
                rows={4}
                className="py-3 px-4 block w-full shadow-sm focus:ring-primary focus:border-primary border-gray-300 rounded-md"
                defaultValue={''}
              />
            </div>
          </div>
          <div className="sm:col-span-2">
            <button
              type="submit"
              className="w-full inline-flex items-center justify-center px-6 py-3 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-primary hover:bg-primary-light focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
            >
              Let&rsquo;s talk
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
);

export default function ContactPage() {
  return (
    <>
      <SEO title="Giftcards Sales and Technical Support" />
      <MenuBar/>
      <Layout>
        <Form/>
        <Footer/>
      </Layout>
    </>
  )
}

